import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import AboveFooterSection from '../../components/AboveFooterSection'

import { StaticImage } from 'gatsby-plugin-image'

const avanza = () => {
  const data = useStaticQuery(graphql`
    query AvanzaSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "AvanzaCaseStudy" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="container-1 hero-margin">
          <div className="d-flex flex-wrap justify-content-center mobile-padding-top-half">
            <div className="col-lg-9 col-md-12 col-12 blog-content-parent ">
              <div className="text-center">
                <StaticImage
                  className="w-20 mobile-w-100"
                  src="../../resources/images/case_studies/avanza/avanza-logo.png"
                  quality={100}
                  alt="Avanza"
                  placeholder="tracedSVG"
                />
              </div>
              <div className="w-100 mx-auto mt-4 mb-3 mobile-margin-top">
                <h1 className="text-center my-2 lh-2-3">
                  Leading the Swedish savings market by safely liberating Kafka
                  data
                </h1>
              </div>
              <p className="f-20 text-center px-5 my-4 mobile-padding-reset">
                How did investment platform Avanza win<b> &gt; 1 million </b>
                customers by enabling engineers with a secure data platform?
              </p>
              <div className="d-flex justify-content-center mb-3 mt-4 mobile-margin-top">
                <StaticImage
                  src="../../resources/images/case_studies/avanza/avanza-hero-new.png"
                  quality={100}
                  placeholder="tracedSVG"
                  alt="Avanza hero"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-cyan mobile-margin-top mt-5">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-around">
            <div className="col-sm col-12 px-4 text-center mobile-margin-top">
              <h2 className="f-32 fw-900">
                20 teams <br /> on Kafka
              </h2>
              <p>including developers, data analysts & product owners.</p>
            </div>
            <div className="col-sm col-12 px-4 text-center mobile-margin-top">
              <h2 className="f-32 fw-900">
                Intelligent <br /> insights
              </h2>
              <p>on available data and associated schema.</p>
            </div>
            <div className="col-sm col-12 px-4 text-center mobile-margin-top">
              <h2 className="f-32 fw-900 pb-4">
                Good <br /> governance
              </h2>
              <p>to safeguard customer data across teams & regulations.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <p>
                Avanza is a leading investment and savings digital platform from
                Sweden, and they have data to protect. So they made it their
                mission to provide a secure data platform to enable developers
                and data engineers to collaborate more effectively.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <div className="bg-white text-left p-4 position-relative">
                <p className="f-19 quote-line-height mb-1">
                  <i>
                    “We want to liberate our data by design in a secure way that
                    is sustainable for the future of Avanza. Our online systems
                    serve our customers, so these need to be protected and kept
                    separate from analytics. Lenses.io offers us the
                    observability to realize this mission - de-coupling
                    real-time operational environments from the data analytics
                    environment by offering observability into Kafka clusters
                    and keeping our event data flowing.”
                  </i>
                </p>
                <p className="primary-text fw-600">
                  <i>Donny Åström Fransson, Data Engineer</i>
                </p>
                <div>
                  <div className="casestudy-quotes">
                    <StaticImage
                      src="../../resources/images/vectors/quotes.svg"
                      placeholder="tracedSVG"
                      alt="quotes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                Life before Kafka
              </h3>
              <p>
                Avanza&apos;s original ambition was to decouple their online
                services from their analytics services, putting circuit breakers
                in place to protect their performance and availability. After
                all, customer-facing apps should be safeguarded.
              </p>
              <p>
                The company was driven by customer insights, but the underlying
                data pipelines needed improvement and modernization. This meant
                a transition from batch data processing and CSVs over FTP
                between different teams - to real-time data and rich integration
                of applications.
              </p>
              <p>
                Apache Kafka was an obvious choice for this transition: Avanza
                offers a renowned customer experience to the Swedish market
                courtesy of their engineering practices - their engineers have
                an Open-Source philosophy, and the team are also fans of the
                event streaming architecture.
              </p>
              <p>
                However, when the team deployed the first Kafka cluster, they
                spotted a big inefficiency. As they operated Kafka with
                Open-Source tooling, a data analyst or developer had to go to
                the platform team every time they wanted something.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-usecase-avanza-1 py-5 mobile-margin-top mt-4">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-12">
              <p className="f-20 text-white text-center mb-0 ">
                “If open-source tooling is limiting your workflow, you’re not
                being strategic! The right tool for the right job sometimes
                means that well-placed procurement is the right option.”
              </p>

              <div className="mt-4 text-center">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <StaticImage
                    src="../../resources/images/case_studies/avanza/donny.png"
                    quality={100}
                    alt="Donny Åström Fransson"
                    placeholder="tracedSVG"
                  />
                  <p className="text-white fw-700 pl-3 mt-2 mobile-padding-reset">
                    Donny Åström Fransson, Data Engineer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <p>
                This was a problem. Increasingly developers and analysts wanted
                self-service access to data and apps: they already had an
                effective API catalog but they needed a{' '}
                <a href="/blog/2020/07/data-dump-real-time-data-catalog-apache-kafka/">
                  data catalog.
                </a>
              </p>
              <p>
                The team realized that not only were they decoupling their
                applications, they were decoupling their teams and products.
              </p>
              <p>
                They needed a new DataOps approach, that puts data at the
                fingertips of developers, data analysts and product owners.
              </p>
              <p>
                Initially, the Platform team considered building their own
                wrapper around the core of Kafka. Lack of tooling was preventing
                them from helping their internal customers in fulfilling their
                strategic initiatives. If there was no way of independently
                retaining an overview on what was happening in Kafka, their plan
                to make data-driven teams more autonomous would fail - and the
                platform engineers would forever be a dependency for future
                innovation.
              </p>
              <p>
                Yet Kafka was powerful and complicated in equal measure, and
                this made self-development of tools too big of a project.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <div className="bg-white text-left p-4 position-relative">
                <p className="f-19 quote-line-height mb-1">
                  <i>
                    “Lenses.io has saved us a lot of time. Because Kafka is an
                    Open-Source stack, there is a lot of tooling that you need
                    to put together to get a complete platform.”
                  </i>
                </p>
                <p className="primary-text fw-600">
                  <i>Anders Eriksson, Data Engineer</i>
                </p>
                <div>
                  <div className="casestudy-quotes">
                    <StaticImage
                      src="../../resources/images/vectors/quotes.svg"
                      placeholder="tracedSVG"
                      alt="quotes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                Apache Kafka observability once and for all
              </h3>
              <p>
                Anders and Donny wanted to build a system where developers could
                publish an event view of their domain once - and they could do
                so without direct involvement from the platform team.
              </p>
              <p>
                They decided to use Lenses for Kafka as a window into their
                cluster and into the data itself.
              </p>
              <p>
                Instead of overnight batch processing, Lenses gave a view into
                the real-time applications and data instantly.
              </p>
              <p>
                At last, developers could see what they had built. With Lenses
                to apply DataOps practices, developers could explore data across
                all their Kafka streams without stress.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-usecase-avanza-2 py-5 mobile-margin-top mt-4">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-12">
              <p className="f-20 text-white text-center mb-0 ">
                “Lenses gives us a wider ability to oversee Kafka. This not only
                helps developers to understand which data is there and how the
                data is represented, but provides a feedback mechanism on the
                schema itself. It’s a big part of our development process.”
              </p>

              <div className="mt-4 text-center">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <StaticImage
                    src="../../resources/images/case_studies/avanza/anders.png"
                    quality={100}
                    alt="Anders Eriksson"
                    placeholder="tracedSVG"
                  />
                  <p className="text-white fw-700 pl-3 mt-2 mobile-padding-reset">
                    Anders Eriksson, Data Engineer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                Preventing Kafka security incidents across 20 teams
              </h3>
              <p>
                Regulations such as the GDPR have been a core data platform
                consideration for Avanza from the very beginning.
              </p>
              <p>
                It is their policy to give maximum data access to different
                teams across the organization whilst masking sensitive
                information with Lenses Data Policies. Data masking is a key
                element of the granular permissions they have given to their
                developers, and each team has its own domain containing relevant
                data that only they should see.
              </p>
              <p>
                The team secures Kafka with TLS and integrated secret
                management. Building all the other security features themselves
                would be too resource-intensive, so they turned to Lenses.io for
                support with these.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                Streaming data platform for financial services
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="footer-color">
          <div className="container-1">
            <div className="d-flex flex-wrap py-3">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom-half">
                <div className="f-16 py-3 mobile-text-center">
                  <div className="text-center mr-4 mobile-margin-reset">
                    <StaticImage
                      className="w-20"
                      src="../../resources/images/usecases/secure/secret-management.svg"
                      alt="Monitoring & Alerting"
                    />
                  </div>
                  <br />
                  <p className="p-text pt-1">
                    <b>Safeguarding for security & governance </b>
                    with data masking, troubleshooting, data cataloguing,
                    auditing and authentication.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom-half">
                <div className="f-16 py-3 mobile-text-center">
                  <div className="text-center mr-4 mobile-margin-reset">
                    <StaticImage
                      className="w-35"
                      src="../../resources/images/usecases/kafka-governance/connector-mgt.svg"
                      alt="App topology for good governance"
                    />
                  </div>
                  <br />
                  <p className="p-text pt-1">
                    <b>App topology for good governance </b>
                    to see connected flows and apps in an interactive map, table
                    or topological format.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom-half">
                <div className="f-16 py-3 mobile-text-center">
                  <div className="text-center mr-4 mobile-margin-reset">
                    <StaticImage
                      className="w-25"
                      src="../../resources/images/usecases/monitor/system.svg"
                      alt="Building Apps with SQL"
                    />
                  </div>
                  <br />
                  <p className="p-text">
                    <b>Building applications with SQL </b>
                    over existing infrastructure - for example Kafka or
                    Kubernetes cluster.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom-half">
                <div className="f-16 py-3 mobile-text-center">
                  <div className="text-center mr-4 mobile-margin-reset">
                    <StaticImage
                      className="w-35"
                      src="../../resources/images/usecases/kafka-governance/data-lineage.svg"
                      alt="Multi-dimensional troubleshooting"
                    />
                  </div>
                  <br />
                  <p className="p-text">
                    <b>Multi-dimensional troubleshooting </b>
                    using insights into app performance & flows to explore
                    topics, replaying data or skipping events.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-usecase-avanza-3 py-5">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-12">
              <p className="f-20 text-white text-center mb-0 ">
                “Lenses gives greater observability over our Kafka cluster,
                saving a lot of time for our Back-end Developers and Data
                Engineers in their quest to get a quick overview of the
                available data and associated schema. This is key to creating a
                truly innovative and fun environment working with data.”
              </p>

              <div className="mt-4 text-center">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <StaticImage
                    src="../../resources/images/case_studies/avanza/fredrik.png"
                    quality={100}
                    alt="Fredrik Thulin"
                    placeholder="tracedSVG"
                  />
                  <p className="text-white fw-700 pl-3 mt-2 mobile-padding-reset">
                    Fredrik Thulin, Product Owner
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                Democratizing data means results for everyone
              </h3>
              <p>
                The ability for anyone to fast-forward and replay through data
                in time, as well as seeing it in a compact format like a table
                or topology, was a dealmaker for Anders and Donny when it came
                to working with Lenses.io.
              </p>
              <p>
                With 20 teams already working with Kafka, Avanza&apos;s
                engineering team is now on a path towards total self-service and
                data socialization across the company.
              </p>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Get your DataOps workspace for Apache Kafka:'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default avanza
